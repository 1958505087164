<template>
  <div class="d-flex flex-column" style="height: 100vh; max-height: 100vh; overflow-y: auto; overflow-x: hidden;">
    <navbar></navbar>
    <div class="d-flex flex-row flex-nowrap flex-grow-1">
      <sidebar></sidebar>
      <div class="container-fluid px-md-4 py-md-3">
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/api'

import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import router from "@/router";

export default {
  components: {
    Sidebar,
    Navbar
  },
  mounted() {
    this.timer_authCheck = setInterval(async () => {
      try{
        await api.checkAuthStatus()
      } catch (e) {
        window.localStorage.removeItem('user')
        await router.push("/login")
      }
    }, 30000)  // check every 30 seconds...
  },
  beforeUnmount: function() {
    clearInterval(this.timer_authCheck)
  },
  data() {
    return {
      timer_authCheck: null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>