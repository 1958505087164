<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary bg-opacity-100 shadow-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">ezDash</router-link>
      <div class="d-flex flex-column" />
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a href="https://q-u-i-l-t.com/" target="_blank" class="nav-link">q-u-i-l-t</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>